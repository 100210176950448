.App {
  text-align: center;
}

.rowContainer {
  /*background-color: black;
  /* width: 25%; */
  /* min-height: 100vh; */
  display: flex;
  /*flex-direction: row;*/
  /*falign-items: stretch;*/
  /*justify-content: center;*/
  /*font-size: calc(10px + 2vmin);*/
  /*color: white;*/
  
}

.movie {
  flex: 1;
  padding: 30px;
  border:4px solid;
  position: relative;
  overflow: hidden;

}

img {
  max-width: 100%;
}

.overlay {
  transition: bottom ease 1s;
  display: none;
  position: absolute;
  bottom: -100px;
  left:0;
  width: 100%;
  background-color: green;
  opacity: 0.6;
}

.movie:hover .overlay {
  display: block;
  position: absolute;
  opacity: .6;
  bottom: 0
}

@media only screen and (max-width: 600px) {
  
  .rowContainer {
    display: block;
  }
}





